<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="orders === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'invoice-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-row
      v-if="orders"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          v-if="orders"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <div
            class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0"
          >
            <div style="color: black">
              <div class="logo-wrapper">
                <img
                  src="@/assets/images/logo/logo.png"
                  width="250px"
                  height="100%"
                >
                <h3 class="text-primary invoice-logo" />
              </div>
            </div>
          </div>

          <div class="d-flex flex-row justify-content-between">
            <div class="ml-3">
              <h1 style="color: black; font-size: 30px">
                Facture
              </h1>
            </div>
            <div>
              <h3
                class="mr-3"
                style="color: black"
              >
                Date:
                <span style="color: black"> {{ date }}</span>
              </h3>
            </div>
          </div>
          <b-card-body class="d-flex flex-row justify-content-between">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                class="mb-2 mt-0 ml-2"
                style="border: 2px solid black"
              >
                <b-card-text class="ml-2 mr-2 mt-2 mb-1 h4 text-dark font-weight-bold">
                  <strong>{{ company.name }} :</strong> Société de livraison des colis
                </b-card-text>
                <b-card-text class="ml-2 h4 text-dark font-weight-bold mr-2 mb-1">
                  <span class="font-weight-bold"><strong>Matricule fiscale :</strong> {{ company.tax_identification_number }} </span>
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1 h4 text-dark font-weight-bold">
                  <span
                    class="font-weight-bold"
                  ><strong>Adresse :</strong> {{ company.address }}
                  </span>
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1 h4 text-dark font-weight-bold">
                  <span
                    class="font-weight-bold"
                  ><strong>Tel :</strong> {{ company.phone1 }}
                  </span>
                </b-card-text>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                class="mb-2 mt-0 ml-2"
                style="border: 2px solid black"
              >
                <b-card-text class="ml-2 mr-2 mt-2 mb-1 h4 text-dark font-weight-bold">
                  <strong> Fournisseur : </strong> {{ supplier.username }}
                </b-card-text>
                <b-card-text class="ml-2 h4 text-dark font-weight-bold mr-2 mb-1">
                  <span class="font-weight-bold"><strong> Matricule fiscale : </strong> {{ supplier.tax_identification_number }} </span>
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1 h4 text-dark font-weight-bold">
                  <span
                    class="font-weight-bold"
                  ><strong> Adresse : </strong> {{ supplier.address }}
                  </span>
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1 h4 text-dark font-weight-bold">
                  <span
                    class="font-weight-bold"
                  ><strong> Tel : </strong> {{ supplier.phone }}
                  </span>
                </b-card-text>
              </div>
            </div>

          </b-card-body>

          <b-card-body
            class=" pb-10 mt-1 d-flex justify-content-between"
            style=""
          >
            <!-- Col: Total -->

            <table style="border: 2px solid black">
              <tr>
                <th>Nombre de colis</th>
                <th>Colis livré argent reçu</th>
                <th>Colis retour</th>
                <th>Total COD</th>
                <th>Frais de livraison</th>
                <th>Net a payer</th>
              </tr>
              <tr>
                <td>{{ nbTotal }}</td>
                <td> {{ delivred.length }}</td>
                <td>{{ returnOrder.length }}</td>
                <td> {{ sommeTTC }}</td>
                <td> {{ sommeLIV +sommeRetour }}</td>
                <td>{{ total }}</td>
              </tr>
            </table>

          </b-card-body>
          <b-card-body
            class="invoice-padding pt-0"
          >
            <p
              class="
            font-weight-bold
            text-dark
            h5
            mb-1"
            >
              <feather-icon
                class="mr-1"
                icon="FolderPlusIcon"
                style="width: 24px;height: 24px;"
                color="#01B2FE"
              />
              <span style="color:#01B2FE">Colis livré argent reçu</span>
            </p>
            <b-table-lite
              class="ta"
              responsive
              :items="delivredOrder"
              :fields="['code','destinataire','tel','prix','prixLivraison']"
            >
              <template
                #cell(code)="data"
              >
                <h5>{{ data.item.code }}</h5>
                <span
                  v-if="data.item.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </template>
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <p class="font-weight-bold text-dark h5 mb-1">
              <feather-icon
                icon="FolderMinusIcon"
                style="width: 24px;height: 24px;"
                class="mr-1"
                color="red"
              />
              <span style="color:red">Colis retour</span>
            </p>
            <b-table-lite
              class="ta"
              responsive
              :items="returnOrder"
              :fields="['code','destinataire','tel','prixRetour']"
            >
              <template
                #cell(code)="data"
              >
                <h5>{{ data.item.code }}</h5>
                <span
                  v-if="data.item.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </template>
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card-body>

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold" />
            <span />
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :class="{ 'disabled-cursor': isLoading }"
            :disabled="isLoading"
            @click="confirmer"
          >
            Confirmer
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BSpinner,
  },
  data() {
    return {
      company: {},
      orders: [],
      delivred: [],
      return: [],
      delivredOrder: [],
      returnOrder: [],
      supplier: '',
      date: null,
      nbTotal: 0,
      user: {},
      isLoading: false,
    }
  },
  created() {
    this.id = this.$route.params.id
    const current = new Date()
    this.date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
    this.company = storeAuth.state.company
    this.user = storeAuth.state.user
    this.getOrders()
  },
  methods: {
    async confirmer() {
      this.isLoading = true
      const obj = {
        returnOrders: this.return,
        delivredOrders: this.delivred,
        supplier: this.supplier.id,
        repository: this.supplier.repository,
        nb_delivered: this.delivred.length,
        nb_return: this.return.length,
        total_nb: this.nbTotal,
        total_ttc: this.sommeTTC,
        total_delivery: this.sommeLIV + this.sommeRetour,
        total: this.total,
        user: this.user,
      }
      try {
        const { data } = await axios.post('/api/invoices/create-invoice/', obj)
        this.$router.push(`/invoices/show/${data}`)
        this.showToast('success', 'top-center', 'Facture ajoutée avec succés')
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
        this.isLoading = false
      }
    },
    async getOrders() {
      this.isLoading = true
      const response = await axios.get('/api/orders/invoices/preview/', {
        params: {
          supplier: this.$route.params.id,
        },
      })
      this.orders = response.data

      this.delivred = this.orders.filter(item => item.status === 14)

      this.supplier = this.orders[0].supplier
      for (let i = 0; i < this.delivred.length; i += 1) {
        const data = {
          code: this.delivred[i].id,
          tel: this.delivred[i].phone1,
          destinataire: this.delivred[i].name,
          adresse: this.delivred[i].address,
          prix: this.delivred[i].price_ttc,
          prixLivraison: this.delivred[i].price_delivery,
          status: 'livré',
          is_an_exchange: this.delivred[i].is_an_exchange,
        }
        this.sommeTTC += Number(this.delivred[i].price_ttc)
        this.sommeLIV += Number(this.delivred[i].price_delivery)
        this.delivredOrder.push(data)
      }
      this.return = this.orders.filter(item => item.status === 7)
      for (let i = 0; i < this.return.length; i += 1) {
        const data = {
          code: this.return[i].id,
          gouvernorat: this.return[i].governorate,
          tel: this.return[i].phone1,
          destinataire: this.return[i].name,
          adresse: this.return[i].address,
          prix: this.return[i].price_ttc,
          prixRetour: this.return[i].price_return,
          is_an_exchange: this.return[i].is_an_exchange,
          status: 'retour',
        }
        this.returnOrder.push(data)
        this.sommeRetour += Number(this.return[i].price_return)
      }
      this.total = this.sommeTTC - (this.sommeLIV + this.sommeRetour)
      this.nbTotal = this.delivred.length + this.return.length
      this.isLoading = false
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const sommeTTC = 0
    const sommeLIV = 0
    const sommeRetour = 0
    const total = 0
    const tax = 0
    const sommeHT = 0

    return {
      invoiceData,
      paymentDetails,
      sommeRetour,
      sommeHT,
      tax,
      total,
      sommeTTC,
      sommeLIV,

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor{
    background-color:#01B2FE ;
  }
.contact {
  background-color: #01B2FE;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }
  .ta {
    white-space: nowrap;
    overflow-x: visible;
    font-size: 12px;
    color: black;
  }
  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor{
    background-color:#01B2FE ;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #01B2FE;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
